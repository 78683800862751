import TextBlock from "components/blocks/TextBlock";
import Footer from "components/Footer";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import { Box } from "rebass";
import { withTheme } from "styled-components";
import BaseTemplate from "templates/base";
import events from "utils/events";
import { findAndConvertAnchorLinks } from "utils/findAndConvertAnchorLinks";
import GlobalEmitter from "utils/GlobalEmitter";

function createToc(htmlString) {
  // Helper function to slugify a string
  const slugify = (text) =>
    text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");

  // Parsing the HTML string
  // const parser = new DOMParser();
  // const doc = parser.parseFromString(htmlString, 'text/html');

  // Building the TOC
  const h2Regex = /<h2>(.*?)<\/h2>/g;
  let modifiedHtml = htmlString;
  let toc = "<h2>Table of Contents</h2><ul>";
  // const h2Elements = doc.querySelectorAll('h2');
  // h2Elements.forEach(h2 => {
  //     const slug = slugify(h2.innerHTML);
  //     h2.id = slug; // Assigning ID to h2 element
  //     toc += `<li><a href="#${slug}" onclick="javascript:dynamicScrollTo('${slug}');">${h2.innerHTML}</a></li>`;
  // });

  let match;
  while ((match = h2Regex.exec(htmlString)) !== null) {
    const content = match[1];
    const slug = slugify(content);

    // Replace h2 element with the one with an ID
    modifiedHtml = modifiedHtml.replace(
      match[0],
      `<h2 id="${slug}">${content}</h2>`
    );

    // Add to TOC
    toc += `<li><a href="#${slug}" onclick="javascript:dynamicScrollTo('${slug}');">${content}</a></li>`;
  }
  toc += "</ul>";

  // Getting the modified HTML string
  // const modifiedHtml = doc.body.innerHTML;

  // Returning the result
  return { modifiedHtml, toc };
}

class ContentPageTemplate extends BaseTemplate {
  constructor(props) {
    super(props);
    const url = props.data.page.url;
    if (url.indexOf("privacy") > -1) {
      const { modifiedHtml, toc } = createToc(this.props.data.page.content);
      this.modifiedContent = findAndConvertAnchorLinks(modifiedHtml);
      this.toc = toc;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.hashTimeout = setTimeout(() => {
      // check if there's a hash in the url
      const hash = window.location.hash;
      const el = document.getElementById(hash.replace("#", ""));
      if (el) {
        GlobalEmitter.emit(events.scrollIntoView, {
          el,
          offset: -25,
          duration: 600,
          centered: false,
        });
      }
    }, 1500);
  }

  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page } = data;
    const { url, heading, seoMetaTags, content } = page;

    const pageContent = this.modifiedContent || content;
    const toc = this.toc;

    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-white.gif" />
        </HelmetDatoCms>
        <Box pt={[150, 150, 80]}>
          {/* Content Info */}
          {heading && (
            <TextBlock
              theme={theme}
              content={heading ? heading.toUpperCase() : ``}
              groupWithNextBlock={true}
              showAside={false}
              columnProps={{ width: [1, 1, 3 / 4] }}
              contentProps={{
                fontFamily: `Druk !important`,
                fontSize: `6rem`,
              }}
            />
          )}
          {toc && (
            <TextBlock
              theme={theme}
              content={toc}
              groupWithNextBlock={false}
              showAside={false}
              columnProps={{ width: [1, 1, 3 / 4] }}
              contentProps={{
                css: {
                  "& ul": {
                    paddingLeft: 0,
                    listStyleType: "none",
                    "& li": {
                      marginBottom: 0,
                      "& a": { color: "#444444", fontWeight: "bold" },
                    },
                  },
                },
              }}
            />
          )}
          {pageContent && (
            <TextBlock
              theme={theme}
              content={pageContent}
              groupWithNextBlock={false}
              showAside={false}
              columnProps={{ width: [1, 1, 3 / 4] }}
              contentProps={{
                css: {
                  "& h3": {
                    fontSize: `1.6rem`,
                  },
                  "& a": { color: "#444444", fontWeight: "bold" },
                },
              }}
            />
          )}
        </Box>
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String) {
    page: datoCmsContentPage(id: { eq: $uid }) {
      url
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heading
      content
    }
  }
`;

export default withTheme(ContentPageTemplate);
